import React from "react";
import { Link } from 'react-router-dom'
import dots from "../../assets/dots.svg";
export default function JobPostingTable() {
  return (
    <div className="commanTable">
      <table>
        <thead>
          <tr>
            <th>Job Title</th>
            <th>Department</th>
            <th>Application End Date</th>
            <th>Status</th>
            <th>Options</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Head of Accounting</td>
            <td>Accounting</td>
            <td>30 Jun 2023</td>
            <td>Draft</td>
            <td>
              <div class="dropdown">
                <button
                  class="btn"
                  type="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <img src={dots} alt="" />
                </button>
                <ul class="dropdown-menu">
                  <li>
                    <Link class="dropdown-item" to="">
                      Action
                    </Link>
                  </li>
                  <li>
                    <Link class="dropdown-item" to="">
                      Another action
                    </Link>
                  </li>
                  <li>
                    <Link class="dropdown-item" to="">
                      Something else here
                    </Link>
                  </li>
                </ul>
              </div>
            </td>
          </tr>
          <tr>
            <td>Head of Accounting</td>
            <td>Accounting</td>
            <td>30 Jun 2023</td>
            <td>Draft</td>
            <td>
              <div class="dropdown">
                <button
                  class="btn"
                  type="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <img src={dots} alt="" />
                </button>
                <ul class="dropdown-menu">
                  <li>
                    <Link class="dropdown-item" to="">
                      Action
                    </Link>
                  </li>
                  <li>
                    <Link class="dropdown-item" to="">
                      Another action
                    </Link>
                  </li>
                  <li>
                    <Link class="dropdown-item" to="">
                      Something else here
                    </Link>
                  </li>
                </ul>
              </div>
            </td>
          </tr>
          <tr>
            <td>Head of Accounting</td>
            <td>Accounting</td>
            <td>30 Jun 2023</td>
            <td>Open</td>
            <td>
              <div class="dropdown">
                <button
                  class="btn"
                  type="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <img src={dots} alt="" />
                </button>
                <ul class="dropdown-menu">
                  <li>
                    <Link class="dropdown-item" to="">
                      Action
                    </Link>
                  </li>
                  <li>
                    <Link class="dropdown-item" to="">
                      Another action
                    </Link>
                  </li>
                  <li>
                    <Link class="dropdown-item" to="">
                      Something else here
                    </Link>
                  </li>
                </ul>
              </div>
            </td>
          </tr>
          <tr>
            <td>Head of Accounting</td>
            <td>Accounting</td>
            <td>30 Jun 2023</td>
            <td>Draft</td>
            <td>
              <div class="dropdown">
                <button
                  class="btn"
                  type="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <img src={dots} alt="" />
                </button>
                <ul class="dropdown-menu">
                  <li>
                    <Link class="dropdown-item" to="">
                      Action
                    </Link>
                  </li>
                  <li>
                    <Link class="dropdown-item" to="">
                      Another action
                    </Link>
                  </li>
                  <li>
                    <Link class="dropdown-item" to="">
                      Something else here
                    </Link>
                  </li>
                </ul>
              </div>
            </td>
          </tr>
          <tr>
            <td>Head of Accounting</td>
            <td>Accounting</td>
            <td>30 Jun 2023</td>
            <td>Draft</td>
            <td>
              <div class="dropdown">
                <button
                  class="btn"
                  type="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <img src={dots} alt="" />
                </button>
                <ul class="dropdown-menu">
                  <li>
                    <Link class="dropdown-item" to="">
                      Action
                    </Link>
                  </li>
                  <li>
                    <Link class="dropdown-item" to="">
                      Another action
                    </Link>
                  </li>
                  <li>
                    <Link class="dropdown-item" to="">
                      Something else here
                    </Link>
                  </li>
                </ul>
              </div>
            </td>
          </tr>
          <tr>
            <td>Head of Accounting</td>
            <td>Accounting</td>
            <td>30 Jun 2023</td>
            <td>Draft</td>
            <td>
              <div class="dropdown">
                <button
                  class="btn"
                  type="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <img src={dots} alt="" />
                </button>
                <ul class="dropdown-menu">
                  <li>
                    <Link class="dropdown-item" to="">
                      Action
                    </Link>
                  </li>
                  <li>
                    <Link class="dropdown-item" to="">
                      Another action
                    </Link>
                  </li>
                  <li>
                    <Link class="dropdown-item" to="">
                      Something else here
                    </Link>
                  </li>
                </ul>
              </div>
            </td>
          </tr>
          <tr>
            <td>Head of Accounting</td>
            <td>Accounting</td>
            <td>30 Jun 2023</td>
            <td>Close</td>
            <td>
              <div class="dropdown">
                <button
                  class="btn"
                  type="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <img src={dots} alt="" />
                </button>
                <ul class="dropdown-menu">
                  <li>
                    <Link class="dropdown-item" to="">
                      Action
                    </Link>
                  </li>
                  <li>
                    <Link class="dropdown-item" to="">
                      Another action
                    </Link>
                  </li>
                  <li>
                    <Link class="dropdown-item" to="">
                      Something else here
                    </Link>
                  </li>
                </ul>
              </div>
            </td>
          </tr>
          <tr>
            <td>Head of Accounting</td>
            <td>Accounting</td>
            <td>30 Jun 2023</td>
            <td>Draft</td>
            <td>
              <div class="dropdown">
                <button
                  class="btn"
                  type="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <img src={dots} alt="" />
                </button>
                <ul class="dropdown-menu">
                  <li>
                    <Link class="dropdown-item" to="">
                      Action
                    </Link>
                  </li>
                  <li>
                    <Link class="dropdown-item" to="">
                      Another action
                    </Link>
                  </li>
                  <li>
                    <Link class="dropdown-item" to="">
                      Something else here
                    </Link>
                  </li>
                </ul>
              </div>
            </td>
          </tr>
          <tr>
            <td>Head of Accounting</td>
            <td>Accounting</td>
            <td>30 Jun 2023</td>
            <td>Draft</td>
            <td>
              <div class="dropdown">
                <button
                  class="btn"
                  type="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <img src={dots} alt="" />
                </button>
                <ul class="dropdown-menu">
                  <li>
                    <Link class="dropdown-item" to="">
                      Action
                    </Link>
                  </li>
                  <li>
                    <Link class="dropdown-item" to="">
                      Another action
                    </Link>
                  </li>
                  <li>
                    <Link class="dropdown-item" to="">
                      Something else here
                    </Link>
                  </li>
                </ul>
              </div>
            </td>
          </tr>
          <tr>
            <td>Head of Accounting</td>
            <td>Accounting</td>
            <td>30 Jun 2023</td>
            <td>Draft</td>
            <td>
              <div class="dropdown">
                <button
                  class="btn"
                  type="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <img src={dots} alt="" />
                </button>
                <ul class="dropdown-menu">
                  <li>
                    <Link class="dropdown-item" to="">
                      Action
                    </Link>
                  </li>
                  <li>
                    <Link class="dropdown-item" to="">
                      Another action
                    </Link>
                  </li>
                  <li>
                    <Link class="dropdown-item" to="">
                      Something else here
                    </Link>
                  </li>
                </ul>
              </div>
            </td>
          </tr>
      
        </tbody>
      </table>
    </div>
  );
}

