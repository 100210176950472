import React,{useState} from "react";
import NothingHere from "./components/NothingHere";
import { Link } from 'react-router-dom'
import { Modal,Row,Col } from "react-bootstrap";
import Inputs from "./components/Inputs";
import Select from "./components/Select";
export default function AddminBlank() {
    // const [show, setShow] = useState(false);
    const [show2, setShow2] = useState(false);
    // const handleClose = () => setShow(false);
    const handleClose2 = () => setShow2(false);
    // const handleShow = () => setShow(true);
    const handleShow2 = () => setShow2(true);
  return (
    <div className="clientData">
         <Modal show={show2} onHide={handleClose2}>
        <Modal.Header closeButton>
          <Modal.Title>
            <span className="subHeading">Add Leave</span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <Row>
        <Col lg={6}>
        <Inputs props={{lable:"First Name",placeh:"Josiah"}}/>
        </Col>
        <Col lg={6}>
         <Inputs props={{lable:"Last Name",placeh:"Bolaji"}}/>
        </Col>
        <Col lg={6}>
          <Select props={{lable:"Leave Type"}}/>
        </Col>
        <Col lg={6}>
         <Inputs props={{lable:"Current Balance",placeh:"12 Days"}}/>
        </Col>
        <Col lg={6}>
         <Inputs props={{lable:"From",placeh:"DD-MM-YYYY"}}/>
        </Col>
        <Col lg={6}>
         <Inputs props={{lable:"To",placeh:"DD-MM-YYYY"}}/>
        </Col>
       </Row>
       <Select props={{lable:"Purpose"}}/>
        
         <label htmlFor="" className='subHeading'>Description</label>
         <textarea name="" id="" cols="30" rows="10" className='popUpTextArea' placeholder='Enter Description'>
         </textarea>
         <div className="text-center mt-5">
          <Link to="" className="mainBtn green">Save</Link>
         </div>
        </Modal.Body>
      </Modal>
          <div className="clientHeader">
        <p>
        Human Resource | Leave Management | <Link to="">Leave Applications</Link>
        </p>
        <Link to="" className="mainBtn" onClick={handleShow2}>Add Leave</Link>
      </div>
        <NothingHere/>
    </div>
  );
}
