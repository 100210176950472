import React, { useState } from "react";
import { Row, Col, Dropdown, Modal } from "react-bootstrap";
import arrow from "../assets/arrow.svg";
import ProgressCard from "./components/ProgressCard";
import CommanTable from "./components/CommanTable";
import chart from "../assets/chart.png";
import RightBar from "./components/RightBar";
import { Link } from "react-router-dom";
import Inputs from "./components/Inputs";
export default function Overview() {
  // const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);
  // const handleClose = () => setShow(false);
  const handleClose2 = () => setShow2(false);
  // const handleShow = () => setShow(true);
  const handleShow2 = () => setShow2(true);
  return (
    <div className="overview">
      <div className="container">
        <Modal show={show2} onHide={handleClose2}>
          <Modal.Header closeButton>
            <Modal.Title>
              <span className="subHeading">Add Task</span>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col lg={6}>
                <Inputs props={{ lable: "Task", placeh: "Task" }} />
              </Col>
              <Col lg={6}>
                <Inputs props={{ lable: "Project", placeh: "Project" }} />
              </Col>
              <Col lg={6}>
                <Inputs props={{ lable: "Unit", placeh: "Unit" }} />
              </Col>
              <Col lg={6}>
                <Inputs
                  props={{ lable: "Assigned to", placeh: "Assigned to" }}
                />
              </Col>
              <Col lg={6}>
                <Inputs props={{ lable: "Due Date", placeh: "DD-MM-YYYY" }} />
              </Col>
            </Row>

            <div className="text-center mt-5 mb-5">
              <Link to="" className="mainBtn modalBtn">
                Add Task
              </Link>
            </div>
          </Modal.Body>
        </Modal>
        <Row>
          <Col lg={8}>
            <div className="overviewCard">
              <h6 className="mainHeading">Ongoing Projects</h6>
              <Row>
                <Col lg={4}>
                  <ProgressCard />
                </Col>
                <Col lg={4}>
                  <ProgressCard />
                </Col>
                <Col lg={4}>
                  <ProgressCard />
                </Col>
              </Row>
            </div>
            <div className="overviewCard">
              <div className="commonHeader">
                <h6 className="mainHeading">List of Task</h6>
                <Link to="" className="commonBtn" onClick={handleShow2}>
                  <span>+</span> Add Task
                </Link>
              </div>
              <CommanTable />
            </div>
            <div className="overviewCard charSection">
              <div className="commonHeader">
                <h6 className="mainHeading">Income vs Expenses</h6>
                <Dropdown>
                  <Dropdown.Toggle id="dropdown-basic">
                    <div className="dropDownArrow">
                      Monthly <img src={arrow} alt="" />
                    </div>
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item href="#/action-1">Weekly</Dropdown.Item>
                    <Dropdown.Item href="#/action-2">Monthly</Dropdown.Item>
                    <Dropdown.Item href="#/action-3">Yearly</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
              <img src={chart} alt="" className="testChart" />
            </div>
          </Col>
          <Col lg={4}>
            <RightBar />
          </Col>
        </Row>
      </div>
    </div>
  );
}
