import React,{useState} from 'react'
import {Container,Row,Col} from "react-bootstrap"
import { Link } from 'react-router-dom'
import eye from "../assets/eye.svg"
export default function Login() {
    const [hideShow, setHideShow] = useState(false)
  return (
    <div className='loginPage'>
        <Container>
            <Row>
                <Col lg={7} className="mx-auto">
                    <h1 className='mainTitle text-center'>GEN 128 Ltd.</h1>
                    <form action="#">
                        <label htmlFor="email">Email/Staff ID</label>
                        <input type="email" placeholder='sample@mail.com/10035' required id='email'/>
                        <label htmlFor="password">Password</label>
                        <div className="passwordBox">
                        <input type={hideShow?"text":"password"} required id='password'/>
                        <img src={eye} alt="" onClick={()=>{
                            setHideShow(!hideShow)
                        }}/>
                        </div>
                        <div className="forgot">
                            <div><input type="checkbox" id='check'/>
                            <label htmlFor='check' className='ms-2'>Remember me </label></div>
                            <Link to="">Forgot Password?</Link>
                        </div>
                        <div className='text-center'>
                        <Link to={"/dashboard"} className='mainBtn'>Login</Link>
                        </div>
                    </form>
                </Col>
            </Row>
        </Container>
    </div>
  )
}
