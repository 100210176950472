import React from 'react'
import { Tabs,Tab } from 'react-bootstrap'
import AttendenceDaily from './AttendenceDaily'
import AttendenceMonthly from './AttendenceMonthly'
import AttendenceSummary from './AttendenceSummary'
export default function Attendance() {
  return (
    <div className='clientData attendance'>
          {/* <div className="clientHeader">
        <p>
        Human Resource | Attendance | <Link to="">Daily Attendance</Link>
        </p>
      </div>
        <div className="attendDate">
            <label htmlFor="attDate" className='subHeading'>Date:</label>
           <div className="dateInp">
           <input type="date" name="" id="" placeholder='01/02/2023'/>
           <img src={calendar} alt="" />
           </div>
            <Link to="" className='mainBtn'>Filter</Link>
        </div>
        <AttendenceTable/>
        <PaginationCom/> */}
            <Tabs defaultActiveKey={"tab1"}>
        <Tab eventKey="tab1" title="Daily Attendance">
       <AttendenceDaily/>
        </Tab>
        <Tab eventKey="tab2" title="Monthly Attendance">
         <AttendenceMonthly/>
        </Tab>
        <Tab eventKey="tab3" title="Summary">
          <AttendenceSummary/>
        </Tab>
      </Tabs>
    </div>
  )
}
