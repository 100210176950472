import React,{useState} from "react";
import ds from "../assets/jp.png";
import { Row, Col,Modal ,Dropdown} from "react-bootstrap";
import { Link } from "react-router-dom";
import view from "../assets/view.svg";
import printico from "../assets/printico.svg";
import backarrow from "../assets/backarrow.svg";
import arrow from "../assets/arrow.svg"
export default function HrJobPostingProfile() {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
  return (
    <div className="clientData profile">
      <div className="clientHeader">
        <p>
          Human Resources | Recruitment | <Link to="">Job Post</Link><br />
          <Link className="mainBtn back"><img src={backarrow} alt="" />Back</Link>
        </p>
        <div className='selectOption dark'>
        <Dropdown>
      <Dropdown.Toggle  id="dropdown-basic">
      <div className="dropDownArrow">
      Shortlisted <img src={arrow} alt="" />
      </div>
      </Dropdown.Toggle>

      <Dropdown.Menu>
        <Dropdown.Item href="#/action-1">Applied</Dropdown.Item>
        <Dropdown.Item href="#/action-2">Shortlisted</Dropdown.Item>
        <Dropdown.Item href="#/action-3">Interviewing</Dropdown.Item>
        <Dropdown.Item href="#/action-3">Rejected</Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
    </div>
      </div>
      <div className="profileCard">
        <div className="container-fluid">
          <Row>
            <Col lg={3}>
              <img src={ds} alt="" />{" "}
            </Col>
            <Col lg={9} className="my-auto">
              <Row>
                <Col lg={4}>
                  <h6 className="smText">Full Name</h6>
                  <h4 className="mb-3">Josiah Haruna Bawa</h4>
                  <h6 className="smText">EMAIL</h6>
                  <h4>sample@mail.com</h4>
                  <h6 className="smText">PHONE NUMBER</h6>
                  <h4>+234 703 603 9249</h4>
                </Col>

                <Col lg={4}>
                  <h6 className="smText">Address</h6>
                  <h4 className="mb-3">
                    2464 Royal Ln. Mesa, New<br></br>Jersey 45463
                  </h4>
                  <h6 className="smText">Role applied</h6>
                  <h4>Backend Developer</h4>
                  <h6 className="smText">date applied</h6>
                  <h4>27th January, 2023 </h4>
                </Col>
                <Col lg={4}>
                  <h6 className="smText">desired pay</h6>
                  <h4 className="mb-3">$610,000</h4>
                  <h6 className="smText">notice period</h6>
                  <h4>2 Weeks</h4>
                  <h6 className="smText">GENDER</h6>
                  <h4>Female</h4>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </div>
      <Row>
        <Col lg={6}>
          <div className="printCard">
            <div className="printCardHeader">
              <div>
                <h6 className="smText">Applicant</h6>
                <h2 className="subHeading">Josiah Haruna Bawa</h2>
              </div>
              <Link to={""} onClick={handleShow}>
                <img src={view} alt="" />
              </Link>
            </div>
            <div className="printCardBody">
              <h1>SUMMARY</h1>
              <p className="mainText">
                Lorem ipsum dolor sit amet consectetur. Sit eu elementum rhoncus
                morbi erat nisl sit. Enim aenean orci dignissim sapien tortor.
              </p>
              <h1>PROFESSIONAL EXPERIENCE</h1>
              <h2 className="subHeading">Backend developer, XYZ LTD</h2>
              <span className="exsmText">JAN 2021- MARCH 2022</span>
              <p className="mainText">
                . Lorem ipsum dolor sit amet consectetur. Sit eu elementum
                rhoncus morbi erat nisl sit. Enim aenean orci dignissim sapien
                tortor. . Lorem ipsum dolor sit amet consectetur. Sit eu
                elementum rhoncus morbi erat nisl sit. Enim aenean orci
                dignissim sapien tortor.
              </p>
              <h2 className="subHeading">Backend developer, STU LTD</h2>
              <span className="exsmText">JUNE 2022 - Present</span>
              <p className="mainText">
                . Lorem ipsum dolor sit amet consectetur. Sit eu elementum
                rhoncus morbi erat nisl sit. Enim aenean orci dignissim sapien
                tortor. . Lorem ipsum dolor sit amet consectetur. Sit eu
                elementum rhoncus morbi erat nisl sit. Enim aenean orci
                dignissim sapien tortor.
              </p>
              <h1>EDUCATION</h1>
              <p className="mainText">
                <span className="subHeading">University of ABC,</span> Bachelor
                of Science - Engineering
              </p>
              <span className="exsmText">MARCH 2021</span>
              <p className="mainText">. Academic Honors</p>
              <p className="mainText">. CGPA 4.90</p>
              <h1>SKILL</h1>
              <p className="mainText">Java, C++, Python,</p>
            </div>
          </div>
        </Col>
        <Col lg={6}>
          <h1 className="mainHeading">Application Questions for this Job</h1>
          <div className="mainText">
            <p>
              1. Why do you want to work here?
              <h6 className="subHeading">I love the company culture</h6>
            </p>

            <p>
              2. What's your biggest strength?
              <h6 className="subHeai">
                Extremely analytical and detail-oriented
              </h6>
            </p>

            <p>
              3. What skills can you bring to this role?
              <h6 className="subHeading">
                I am proficient at Excel, budgeting, forecasting, and financial
                modelling
              </h6>
            </p>

            <p>
              References (Name, Company, and Contact Info)
              <h6 className="subHeading">
                Dean Thomas Financial Company Senior Financial Analyst
                555-555-5555 email@email.com
              </h6>
            </p>
          </div>
        </Col>
      </Row>
      <Modal show={show} onHide={handleClose} size="lg">
      <Row>
        <Col>
          <div className="printCard">
            <div className="printCardHeader">
              <div>
                <h6 className="smText">Applicant</h6>
                <h2 className="subHeading">Josiah Haruna Bawa</h2>
              </div>
              <Link to={""} onClick={handleShow}>
                <img src={printico} alt="" />
              </Link>
            </div>
            <div className="printCardBody">
              <h1>SUMMARY</h1>
              <p className="mainText">
                Lorem ipsum dolor sit amet consectetur. Sit eu elementum rhoncus
                morbi erat nisl sit. Enim aenean orci dignissim sapien tortor.
              </p>
              <h1>PROFESSIONAL EXPERIENCE</h1>
              <h2 className="subHeading">Backend developer, XYZ LTD</h2>
              <span className="exsmText">JAN 2021- MARCH 2022</span>
              <p className="mainText">
                . Lorem ipsum dolor sit amet consectetur. Sit eu elementum
                rhoncus morbi erat nisl sit. Enim aenean orci dignissim sapien
                tortor. . Lorem ipsum dolor sit amet consectetur. Sit eu
                elementum rhoncus morbi erat nisl sit. Enim aenean orci
                dignissim sapien tortor.
              </p>
              <h2 className="subHeading">Backend developer, STU LTD</h2>
              <span className="exsmText">JUNE 2022 - Present</span>
              <p className="mainText">
                . Lorem ipsum dolor sit amet consectetur. Sit eu elementum
                rhoncus morbi erat nisl sit. Enim aenean orci dignissim sapien
                tortor. . Lorem ipsum dolor sit amet consectetur. Sit eu
                elementum rhoncus morbi erat nisl sit. Enim aenean orci
                dignissim sapien tortor.
              </p>
              <h1>EDUCATION</h1>
              <p className="mainText">
                <span className="subHeading">University of ABC,</span> Bachelor
                of Science - Engineering
              </p>
              <span className="exsmText">MARCH 2021</span>
              <p className="mainText">. Academic Honors</p>
              <p className="mainText">. CGPA 4.90</p>
              <h1>SKILL</h1>
              <p className="mainText">Java, C++, Python,</p>
            </div>
          </div>
        </Col>
      </Row>
      </Modal>
    </div>
  );
}
