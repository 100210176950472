 const SideMenuData={
    overview:require("../../assets/Dashboard.svg").default,
    clients:require("../../assets/Clients.svg").default,
    appointment:require("../../assets/Appointment.svg").default,
    project:require("../../assets/project.svg").default,
    human:require("../../assets/human.svg").default,
    acc:require("../../assets/Accounting.svg").default,
    user:require("../../assets/Add User.svg").default,
    arrow:require("../../assets/arrow.svg").default,
    logout:require("../../assets/logout.svg").default,
    setting:require("../../assets/setting.svg").default,
}
export default SideMenuData
