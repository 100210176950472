import React from "react";
import SideMenuData from "./SideBarImg";
import { Link } from "react-router-dom";
export default function SideBar() {
  return (
    <div className="sideBar">
      <h1 className="mainTitle">GEN 128 Ltd.</h1>
      <div className="sideMenu">
        <Link to="/dashboard">
          <img src={SideMenuData.overview} alt="" />
          Overview
        </Link>

        <p>
          <Link
            class="sideCollapes"
            data-bs-toggle="collapse"
            to="#collapseExample1"
            role="button"
            aria-expanded="false"
            aria-controls="collapseExample"
          >
            <img src={SideMenuData.clients} alt="" />
            Clients
            <img src={SideMenuData.arrow} alt="" className="arrowdown"/>
          </Link>
        </p>
        <div class="collapse" id="collapseExample1">
          <div class="card card-body">
           <Link to={"/dashboard/ClientDataBlank"}>Client Data</Link>
           <Link to={"/dashboard/Leads"}>Leads</Link>
          </div>
        </div>

        <Link to={"/dashboard/AppointmentCalender"}><img src={SideMenuData.appointment} alt="" />Appointment</Link>

        <p>
          <Link
            class="sideCollapes"
            data-bs-toggle="collapse"
            to="#collapseExample2"
            role="button"
            aria-expanded="false"
            aria-controls="collapseExample"
          >
            <img src={SideMenuData.project} alt="" />
            Project Management 
            <img src={SideMenuData.arrow} alt="" className="arrowdown"/>
          </Link>
        </p>
        <div class="collapse" id="collapseExample2">
          <div class="card card-body">
           <Link to="/dashboard/AppointmentTableWeek">lorem</Link>
           <Link to="/dashboard/AppointmentCalenderDay">lorem</Link>
           <Link to="/dashboard/AddminLeave">lorem</Link>
           <Link to="/dashboard/AttendenceDaily">lorem</Link>
           <Link to="/dashboard/AttendenceSummary">lorem</Link>
           <Link to="/dashboard/HrJobPostingProfile">Job Post</Link>
           <Link to="/dashboard/Canditate">Job Posting List and Applicants</Link>
           <Link to="/dashboard/JobPosting">Job Posting List</Link>
          </div>
        </div>

        <p>
          <Link
            class="sideCollapes"
            data-bs-toggle="collapse"
            to="#collapseExample3"
            role="button"
            aria-expanded="false"
            aria-controls="collapseExample"
          >
            <img src={SideMenuData.human} alt="" />
            Human Resource
            <img src={SideMenuData.arrow} alt="" className="arrowdown"/>
          </Link>
        </p>
        <div class="collapse" id="collapseExample3">
          <div class="card card-body">
           <Link to={"/dashboard/HrDashboard"}>Employee Management</Link>
           <Link to={"/dashboard/AddminBlank"}>Leave Management</Link>
           <Link to={"/dashboard/Attendance"}>Attendance</Link>
           <Link to={"/dashboard/JobPostingBlank"}>Recruitment</Link>
           <Link to={"/dashboard/Payroll"}>Payroll</Link>
          </div>
        </div>

        <p>
          <Link
            class="sideCollapes"
            data-bs-toggle="collapse"
            to="#collapseExample4"
            role="button"
            aria-expanded="false"
            aria-controls="collapseExample"
          >
            <img src={SideMenuData.acc} alt="" />
            Accounting
            <img src={SideMenuData.arrow} alt="" className="arrowdown"/>
          </Link>
        </p>
        <div class="collapse" id="collapseExample4">
          <div class="card card-body">
           <Link to={"/dashboard/AccIncome"}>Income</Link>
           <Link to={"/dashboard/AccExpense"}>Expenses</Link>
          </div>
        </div>

        <Link to="" className="inviteTeam"><img src={SideMenuData.user} alt=""/> Invite Teammate</Link>
        <Link to=""><img src={SideMenuData.setting} alt="" /> Settings</Link>
        <Link to=""><img src={SideMenuData.logout} alt="" /> Logout</Link>
      </div>
    </div>
  );
}
