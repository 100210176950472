import React from "react";
import { Link } from "react-router-dom";
import h1 from "../../assets/hr (1).png";
import h2 from "../../assets/hr (2).png";
import h3 from "../../assets/hr (3).png";
import h4 from "../../assets/hr (4).png";
import h5 from "../../assets/hr (5).png";
import h6 from "../../assets/hr (6).png";
import h7 from "../../assets/hr (7).png";
import h8 from "../../assets/hr (8).png";
import h9 from "../../assets/hr (9).png";
import dots from "../../assets/dots.svg";
import edit from "../../assets/Edit.svg";
import eye from "../../assets/soeye.svg";
import delet from "../../assets/Delete.svg";
export default function HrTable() {
  return (
    <div className="commanTable hrTable">
      <table>
        <thead>
          <tr>
            <th>Name</th>
            <th>Title</th>
            <th>Department</th>
            <th>Phone</th>
            <th>Staff ID</th>
            <th>Date Joined</th>
            <th>Status</th>
            <th>Options</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <img src={h1} alt="" />
              <Link to={"/dashboard/HrDashboardEmp"}>
              Josiah Chukuma Femi
              </Link>
            </td>
            <td>
            <Link to={"/dashboard/HrDashboardEmp"}>
              Project Engineer
              </Link>
              </td>
            <td>
            <Link to={"/dashboard/HrDashboardEmp"}>
              Electrical
              </Link>
              </td>
            <td>
            <Link to={"/dashboard/HrDashboardEmp"}>
              +234 703 603 9249
              </Link>
              </td>
            <td>
            <Link to={"/dashboard/HrDashboardEmp"}>
              433
              </Link>
              </td>
            <td>
            <Link to={"/dashboard/HrDashboardEmp"}>
              27 January 2022
              </Link>
              </td>
            <td>
            <Link to={"/dashboard/HrDashboardEmp"}>
              Probation
              </Link>
              </td>
            <td>
              <div class="dropdown">
                <button
                  class="btn"
                  type="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <img src={dots} alt="" />
                </button>
                <ul class="dropdown-menu">
                  <li>
                    <Link class="dropdown-item" to="">
                      <img src={edit} alt="" />
                      Edit
                    </Link>
                  </li>
                  <li>
                    <Link class="dropdown-item" to="">
                      <img src={eye} alt="" />
                      View
                    </Link>
                  </li>
                  <li>
                    <Link class="dropdown-item deleteOption" to="">
                      <img src={delet} alt="" />
                      Delete User
                    </Link>
                  </li>
                </ul>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <img src={h2} alt="" />
               <Link to={"/dashboard/HrDashboardEmp"}>
              Josiah Chukuma Femi
              </Link>
            </td>
            <td>
            <Link to={"/dashboard/HrDashboardEmp"}>
              Project Engineer
              </Link>
              </td>
            <td>
            <Link to={"/dashboard/HrDashboardEmp"}>
              Electrical
              </Link>
              </td>
            <td>
            <Link to={"/dashboard/HrDashboardEmp"}>
              +234 703 603 9249
              </Link>
              </td>
            <td>
            <Link to={"/dashboard/HrDashboardEmp"}>
              433
              </Link>
              </td>
            <td>
            <Link to={"/dashboard/HrDashboardEmp"}>
              27 January 2022
              </Link>
              </td>
            <td>
            <Link to={"/dashboard/HrDashboardEmp"}>
              Probation
              </Link>
              </td>
            <td>
              <div class="dropdown">
                <button
                  class="btn"
                  type="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <img src={dots} alt="" />
                </button>
                <ul class="dropdown-menu">
                  <li>
                    <Link class="dropdown-item" to="">
                      <img src={edit} alt="" />
                      Edit
                    </Link>
                  </li>
                  <li>
                    <Link class="dropdown-item" to="">
                      <img src={eye} alt="" />
                      View
                    </Link>
                  </li>
                  <li>
                    <Link class="dropdown-item deleteOption" to="">
                      <img src={delet} alt="" />
                      Delete User
                    </Link>
                  </li>
                </ul>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <img src={h3} alt="" />
               <Link to={"/dashboard/HrDashboardEmp"}>
              Josiah Chukuma Femi
              </Link>
            </td>
            <td>
            <Link to={"/dashboard/HrDashboardEmp"}>
              Project Engineer
              </Link>
              </td>
            <td>
            <Link to={"/dashboard/HrDashboardEmp"}>
              Electrical
              </Link>
              </td>
            <td>
            <Link to={"/dashboard/HrDashboardEmp"}>
              +234 703 603 9249
              </Link>
              </td>
            <td>
            <Link to={"/dashboard/HrDashboardEmp"}>
              433
              </Link>
              </td>
            <td>
            <Link to={"/dashboard/HrDashboardEmp"}>
              27 January 2022
              </Link>
              </td>
            <td>
            <Link to={"/dashboard/HrDashboardEmp"}>
              Probation
              </Link>
              </td>
            <td>
              <div class="dropdown">
                <button
                  class="btn"
                  type="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <img src={dots} alt="" />
                </button>
                <ul class="dropdown-menu">
                  <li>
                    <Link class="dropdown-item" to="">
                      <img src={edit} alt="" />
                      Edit
                    </Link>
                  </li>
                  <li>
                    <Link class="dropdown-item" to="">
                      <img src={eye} alt="" />
                      View
                    </Link>
                  </li>
                  <li>
                    <Link class="dropdown-item deleteOption" to="">
                      <img src={delet} alt="" />
                      Delete User
                    </Link>
                  </li>
                </ul>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <img src={h4} alt="" />
               <Link to={"/dashboard/HrDashboardEmp"}>
              Josiah Chukuma Femi
              </Link>
            </td>
            <td>
            <Link to={"/dashboard/HrDashboardEmp"}>
              Project Engineer
              </Link>
              </td>
            <td>
            <Link to={"/dashboard/HrDashboardEmp"}>
              Electrical
              </Link>
              </td>
            <td>
            <Link to={"/dashboard/HrDashboardEmp"}>
              +234 703 603 9249
              </Link>
              </td>
            <td>
            <Link to={"/dashboard/HrDashboardEmp"}>
              433
              </Link>
              </td>
            <td>
            <Link to={"/dashboard/HrDashboardEmp"}>
              27 January 2022
              </Link>
              </td>
            <td>
            <Link to={"/dashboard/HrDashboardEmp"}>
              Probation
              </Link>
              </td>
            <td>
              <div class="dropdown">
                <button
                  class="btn"
                  type="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <img src={dots} alt="" />
                </button>
                <ul class="dropdown-menu">
                  <li>
                    <Link class="dropdown-item" to="">
                      <img src={edit} alt="" />
                      Edit
                    </Link>
                  </li>
                  <li>
                    <Link class="dropdown-item" to="">
                      <img src={eye} alt="" />
                      View
                    </Link>
                  </li>
                  <li>
                    <Link class="dropdown-item deleteOption" to="">
                      <img src={delet} alt="" />
                      Delete User
                    </Link>
                  </li>
                </ul>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <img src={h5} alt="" />
               <Link to={"/dashboard/HrDashboardEmp"}>
              Josiah Chukuma Femi
              </Link>
            </td>
            <td>
            <Link to={"/dashboard/HrDashboardEmp"}>
              Project Engineer
              </Link>
              </td>
            <td>
            <Link to={"/dashboard/HrDashboardEmp"}>
              Electrical
              </Link>
              </td>
            <td>
            <Link to={"/dashboard/HrDashboardEmp"}>
              +234 703 603 9249
              </Link>
              </td>
            <td>
            <Link to={"/dashboard/HrDashboardEmp"}>
              433
              </Link>
              </td>
            <td>
            <Link to={"/dashboard/HrDashboardEmp"}>
              27 January 2022
              </Link>
              </td>
            <td>
            <Link to={"/dashboard/HrDashboardEmp"}>
              Probation
              </Link>
              </td>
            <td>
              <div class="dropdown">
                <button
                  class="btn"
                  type="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <img src={dots} alt="" />
                </button>
                <ul class="dropdown-menu">
                  <li>
                    <Link class="dropdown-item" to=""></Link>
                  </li>
                  <li>
                    <Link class="dropdown-item" to="">
                      <img src={eye} alt="" />
                      View
                    </Link>
                  </li>
                  <li>
                    <Link class="dropdown-item deleteOption" to="">
                      <img src={delet} alt="" />
                      Delete User
                    </Link>
                  </li>
                </ul>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <img src={h6} alt="" />
               <Link to={"/dashboard/HrDashboardEmp"}>
              Josiah Chukuma Femi
              </Link>
            </td>
            <td>
            <Link to={"/dashboard/HrDashboardEmp"}>
              Project Engineer
              </Link>
              </td>
            <td>
            <Link to={"/dashboard/HrDashboardEmp"}>
              Electrical
              </Link>
              </td>
            <td>
            <Link to={"/dashboard/HrDashboardEmp"}>
              +234 703 603 9249
              </Link>
              </td>
            <td>
            <Link to={"/dashboard/HrDashboardEmp"}>
              433
              </Link>
              </td>
            <td>
            <Link to={"/dashboard/HrDashboardEmp"}>
              27 January 2022
              </Link>
              </td>
            <td>
            <Link to={"/dashboard/HrDashboardEmp"}>
              Probation
              </Link>
              </td>
            <td>
              <div class="dropdown">
                <button
                  class="btn"
                  type="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <img src={dots} alt="" />
                </button>
                <ul class="dropdown-menu">
                  <li>
                    <Link class="dropdown-item" to="">
                      <img src={edit} alt="" />
                      Edit
                    </Link>
                  </li>
                  <li>
                    <Link class="dropdown-item" to="">
                      <img src={eye} alt="" />
                      View
                    </Link>
                  </li>
                  <li>
                    <Link class="dropdown-item deleteOption" to="">
                      <img src={delet} alt="" />
                      Delete User
                    </Link>
                  </li>
                </ul>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <img src={h7} alt="" />
               <Link to={"/dashboard/HrDashboardEmp"}>
              Josiah Chukuma Femi
              </Link>
            </td>
            <td>
            <Link to={"/dashboard/HrDashboardEmp"}>
              Project Engineer
              </Link>
              </td>
            <td>
            <Link to={"/dashboard/HrDashboardEmp"}>
              Electrical
              </Link>
              </td>
            <td>
            <Link to={"/dashboard/HrDashboardEmp"}>
              +234 703 603 9249
              </Link>
              </td>
            <td>
            <Link to={"/dashboard/HrDashboardEmp"}>
              433
              </Link>
              </td>
            <td>
            <Link to={"/dashboard/HrDashboardEmp"}>
              27 January 2022
              </Link>
              </td>
            <td>
            <Link to={"/dashboard/HrDashboardEmp"}>
              Probation
              </Link>
              </td>
            <td>
              <div class="dropdown">
                <button
                  class="btn"
                  type="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <img src={dots} alt="" />
                </button>
                <ul class="dropdown-menu">
                  <li>
                    <Link class="dropdown-item" to="">
                      <img src={edit} alt="" />
                      Edit
                    </Link>
                  </li>
                  <li>
                    <Link class="dropdown-item" to="">
                      <img src={eye} alt="" />
                      View
                    </Link>
                  </li>
                  <li>
                    <Link class="dropdown-item deleteOption" to="">
                      <img src={delet} alt="" />
                      Delete User
                    </Link>
                  </li>
                </ul>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <img src={h8} alt="" />
               <Link to={"/dashboard/HrDashboardEmp"}>
              Josiah Chukuma Femi
              </Link>
            </td>
            <td>
            <Link to={"/dashboard/HrDashboardEmp"}>
              Project Engineer
              </Link>
              </td>
            <td>
            <Link to={"/dashboard/HrDashboardEmp"}>
              Electrical
              </Link>
              </td>
            <td>
            <Link to={"/dashboard/HrDashboardEmp"}>
              +234 703 603 9249
              </Link>
              </td>
            <td>
            <Link to={"/dashboard/HrDashboardEmp"}>
              433
              </Link>
              </td>
            <td>
            <Link to={"/dashboard/HrDashboardEmp"}>
              27 January 2022
              </Link>
              </td>
            <td>
            <Link to={"/dashboard/HrDashboardEmp"}>
              Probation
              </Link>
              </td>
            <td>
              <div class="dropdown">
                <button
                  class="btn"
                  type="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <img src={dots} alt="" />
                </button>
                <ul class="dropdown-menu">
                  <li>
                    <Link class="dropdown-item" to="">
                      <img src={edit} alt="" />
                      Edit
                    </Link>
                  </li>
                  <li>
                    <Link class="dropdown-item" to="">
                      <img src={eye} alt="" />
                      View
                    </Link>
                  </li>
                  <li>
                    <Link class="dropdown-item deleteOption" to="">
                      <img src={delet} alt="" />
                      Delete User
                    </Link>
                  </li>
                </ul>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <img src={h9} alt="" />
               <Link to={"/dashboard/HrDashboardEmp"}>
              Josiah Chukuma Femi
              </Link>
            </td>
            <td>
            <Link to={"/dashboard/HrDashboardEmp"}>
              Project Engineer
              </Link>
              </td>
            <td>
            <Link to={"/dashboard/HrDashboardEmp"}>
              Electrical
              </Link>
              </td>
            <td>
            <Link to={"/dashboard/HrDashboardEmp"}>
              +234 703 603 9249
              </Link>
              </td>
            <td>
            <Link to={"/dashboard/HrDashboardEmp"}>
              433
              </Link>
              </td>
            <td>
            <Link to={"/dashboard/HrDashboardEmp"}>
              27 January 2022
              </Link>
              </td>
            <td>
            <Link to={"/dashboard/HrDashboardEmp"}>
              Probation
              </Link>
              </td>
            <td>
              <div class="dropdown">
                <button
                  class="btn"
                  type="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <img src={dots} alt="" />
                </button>
                <ul class="dropdown-menu">
                  <li>
                    <Link class="dropdown-item" to="">
                      <img src={edit} alt="" />
                      Edit
                    </Link>
                  </li>
                  <li>
                    <Link class="dropdown-item" to="">
                      <img src={eye} alt="" />
                      View
                    </Link>
                  </li>
                  <li>
                    <Link class="dropdown-item deleteOption" to="">
                      <img src={delet} alt="" />
                      Delete User
                    </Link>
                  </li>
                </ul>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}
