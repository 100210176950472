import React from "react";
import AddminLeaveTable from "./components/AddminLeaveTable";
import PaginationCom from "./components/PaginationComp";
import { Link } from 'react-router-dom'
export default function AddminLeave() {
  return (
    <div className="clientData">
      <div className="clientHeader mt-4 mb-3">
        <p>
          Dashboard | Leave Management | Leave Applications |{" "}
          <Link to="">Human Resources</Link>
        </p>
        <div className='sortSelect'>
        <label htmlFor="" className='subHeading'>Filter:</label> <div className='filter'><span>Project Engineer</span>
        <span>Electrical</span>
        <span>+</span>
        </div>
        </div>
      </div>
      <AddminLeaveTable />
      <PaginationCom />
    </div>
  );
}
