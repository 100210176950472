import React,{useState} from 'react'
import { Dropdown } from 'react-bootstrap';
import Calendar from 'react-calendar';
import { Link } from 'react-router-dom';
import arrow from "../assets/arrow.svg"
import Inputs from "./components/Inputs";
import guest from "../assets/User.svg"
import gmeet from "../assets/Google Meet.svg"
import team from "../assets/Teams.svg"
import zoom from "../assets/Zoom.svg"
import { Row,Col,Offcanvas } from 'react-bootstrap';
export default function AppointmentCalender() {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [value, onChange] = useState(new Date());
  return (
    <div className='clientData'>
               <Offcanvas show={show} onHide={handleClose} placement="end">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>New Schedule</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
         <Inputs props={{lable:"Title",placeh:"John Doe"}}/>
         <Row className='dateHr '>
          <Col lg={3}><input type="date" name="" id="" placeholder='DD/MM/YY'/></Col>
          <Col lg={3}><input type="time" name="" id="" placeholder='HH:MM'/></Col>
          <Col lg={3}><input type="date" name="" id="" placeholder='DD/MM/YY'/></Col>
          <Col lg={3}><input type="time" name="" id="" placeholder='HH:MM'/></Col>
         </Row>
         <Inputs props={{lable:"Location"}}/>
         <label htmlFor="" className='subHeading'>Add Guest</label>
       <div>
       <span className='guest'><img src={guest} alt="" /> josiahg@hanstechnology.biz</span>
         <span className='guest'><img src={guest} alt="" /> daniele@gen128bs.com</span>
         <span className='guest'>+</span>
       </div>

        <div className="meetings">
        <img src={gmeet} alt="" /><Link to="">Add Google Meet link</Link>
        <img src={team} alt="" className='ms-5'/><Link to="">Add MS Team link</Link><br></br>
        <img src={zoom} alt="" className='mt-5'/><Link to="">Add Zoom link</Link>
        </div>
        <Inputs props={{lable:"Link" , placeh:"meet.google.com/yi9Dcw"}}/>
         <label htmlFor="" className='subHeading'>Description</label>
         <textarea name="" id="" cols="30" rows="10" className='popUpTextArea' placeholder='Enter Description'>
         </textarea>
        <div className='text-center mt-5'>
        <Link to="" className='mainBtn green'>Save</Link>
        </div>
        </Offcanvas.Body>
      </Offcanvas>
            <div className="clientHeader">
            <p><Link to="">Appointment</Link></p>
            <div>
              <Link to="" className="mainBtn" onClick={handleShow}>Add Appointment</Link>
            </div>
        </div>
          <div className="calenderContainer">
            <div className="commonHeader">
                <span>
                    <Link to={""}>Today</Link>
                </span>
                <Dropdown>
                  <Dropdown.Toggle id="dropdown-basic">
                    <div className="dropDownArrow">
                      Monthly <img src={arrow} alt="" />
                    </div>
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item href="#/action-1">Weekly</Dropdown.Item>
                    <Dropdown.Item href="#/action-2">Monthly</Dropdown.Item>
                    <Dropdown.Item href="#/action-3">Yearly</Dropdown.Item>
                    <Dropdown.Item href="#/action-3">Day</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
            </div>
          <Calendar onChange={onChange} value={value} />
          </div>
    </div>
  )
}
