import React from 'react'
import {ProgressBar} from "react-bootstrap"
import user from "../../assets/user2.png"
import dots from "../../assets/dots.svg"
import { Link } from 'react-router-dom'
export default function ProgressCard() {
  return (
    <div className='progressCard'>
        <div className="cardHeader">
        <p className="date">Start Date: 20 Dec 2022</p>
       
        <div class="dropdown">
  <button class="btn" type="button" data-bs-toggle="dropdown" aria-expanded="false">
  <img src={dots} alt="" />
  </button>
  <ul class="dropdown-menu">
    <li><Link class="dropdown-item" to="">New Department </Link></li>
    <li><Link class="dropdown-item" to="">New Task</Link></li>
    <li><Link class="dropdown-item" to="">Rename Project</Link></li>
    <li><Link class="dropdown-item" to="">Add User</Link></li>
    <li><Link class="dropdown-item" to="">Remove User</Link></li>
  </ul>
</div>
        </div>
        <h1 className="cardHeading">ATLANTIC HALL SCHOOL</h1>
        <div className="progressCount">
            <h6>Progress</h6>
            <h6>81%</h6>
        </div>
        <ProgressBar now={81} />
        <h6>Assigned to</h6>
        <div className="cardFooter">
        <div className="userimg">
            <img src={user} alt="" />
            <span>John Doe</span>
        </div>
        <span className='daysLable'>2 Weeks Left</span>
        </div>
    </div>
  )
}
